<template>
    <div id="app">
        <div class="container">
            <img alt="Vue logo" src="./assets/header.jpg" style="width:200px">

            <h1>❤ Virginie & Maxime ❤</h1>

            <div class="alert alert-light mb-3" role="alert">
                <p>L’heure de votre enterrement de vie de jeune fille et jeune garçon approche les amis !<br>
                    Enfin…y’a bien longtemps qu’elle a été enterrée celle-là… 🙂<br><br>
                    Vous vous demandez certainement où nous allons nous retrouver. Eh bien encore un peu de patience, vous allez pouvoir le découvrir tout au long de cette semaine.<br>
                    Et pour se faire, il va falloir jouer avec un quizz de culture générale vous permettant de découvrir la photo de notre point de ralliement.<br>(Pas de triche, Google n'est pas autorisé)<br><br>
                    C’est très simple, chaque jour vous aurez 10 questions (À partir de ce lundi et jusqu'à vendredi).
                    Chaque bonne réponse vous permettra de découvrir une petite partie de la photo mystère.
                    Alors, serez-vous reconnaître votre point de rendez-vous ?<br><br>
                    Concentrez vous et soyez bon pour trouver le point de ralliement, ça nous embêterait de devoir faire votre weekend d'enterrement de vie célibataire sans vous 🙂<br><br>
                    À vous de jouer !</p>
            </div>

            <div class="alert alert-light mb-3 text-left" role="alert">
                <p>
                    <b>Quelques infos précieuses à savoir</b><br><br>
                    Il faut strictement respecter la nomenclature suivante :<br>
                    - Les dates doivent être au format JJ/MM/AAAA (ex: 11/12/2021)<br>
                    - Les numéros de siècle doivent être en chiffre uniquement (XXe = 20)<br>
                    - Les personnes doivent être indiquées au format Prénom Nom (ex: Clara Morgane)<br>
                    - Il ne doit pas y avoir d’accent, ni de caractère spécial.<br>
                    - Les - doivent être remplacés par « des espaces »<br>
                    - Pour les réponses concernant des distances, des poids… ne pas préciser les unités<br><br>
                    Si le mot "raté" apparaît après avoir répondu, soit vous avez tout simplement donné la mauvaise réponse, soit vous n'avez pas respecté les consignes que nous venons de vous indiquer 😉
                </p>
            </div>

            <div v-for="(day, date) in days" :key="'day' + date">
                <div v-if="currentDay === date">
                    <div class="card mb-3" v-for="(enigma, index) in day" :key="'enigma' + index" v-show="enigma.view">
                        <form action="">
                            <div class="card-header">
                                <h2>Enigme {{ enigma.enigma }} - {{ enigma.theme }}</h2>
                            </div>

                            <div class="card-body">
                                <div v-if="enigma.description">
                                    {{ enigma.description }}
                                    <br><br>
                                </div>

                                <div v-if="enigma.image">
                                    <img :src="enigma.image" alt=""><br><br>
                                </div>

                                <input type="text" placeholder="Ma réponse" class="form-control" name="response" :ref="'enigma' + index">
                                <small v-if="enigma.found === false" class="badge badge-danger">Raté ! </small>
                            </div>

                            <div class="card-footer">
                                <button class="btn btn-success" @click.prevent="submit(enigma, 'enigma' + index, $event)">Je valide ma réponse 🙏</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div class="card mt-3">
                <div class="card-header">
                    <h2>⬇ Retrouvons nous ici pour l'EVG ⬇</h2>
                </div>

                <div class="card-body">
                    <div class="pictures">
                        <div class="picture"><span>1</span><img src="./assets/11-01.png" alt="" v-if="picture1"></div>
                        <div class="picture"><span>2</span><img src="./assets/11-02.png" alt="" v-if="picture2"></div>
                        <div class="picture"><span>3</span><img src="./assets/11-03.png" alt="" v-if="picture3"></div>
                        <div class="picture"><span>4</span><img src="./assets/11-04.png" alt="" v-if="picture4"></div>
                        <div class="picture"><span>5</span><img src="./assets/11-05.png" alt="" v-if="picture5"></div>
                        <div class="picture"><span>6</span><img src="./assets/11-06.png" alt="" v-if="picture6"></div>
                        <div class="picture"><span>7</span><img src="./assets/11-07.png" alt="" v-if="picture7"></div>
                        <div class="picture"><span>8</span><img src="./assets/11-08.png" alt="" v-if="picture8"></div>
                        <div class="picture"><span>9</span><img src="./assets/11-09.png" alt="" v-if="picture9"></div>
                        <div class="picture"><span>10</span><img src="./assets/11-10.png" alt="" v-if="picture10"></div>

                        <div class="picture"><span>11</span><img src="./assets/11-11.png" alt="" v-if="picture11"></div>
                        <div class="picture"><span>12</span><img src="./assets/11-12.png" alt="" v-if="picture12"></div>
                        <div class="picture"><span>13</span><img src="./assets/11-13.png" alt="" v-if="picture13"></div>
                        <div class="picture"><span>14</span><img src="./assets/11-14.png" alt="" v-if="picture14"></div>
                        <div class="picture"><span>15</span><img src="./assets/11-15.png" alt="" v-if="picture15"></div>
                        <div class="picture"><span>16</span><img src="./assets/11-16.png" alt="" v-if="picture16"></div>
                        <div class="picture"><span>17</span><img src="./assets/11-17.png" alt="" v-if="picture17"></div>
                        <div class="picture"><span>18</span><img src="./assets/11-18.png" alt="" v-if="picture18"></div>
                        <div class="picture"><span>19</span><img src="./assets/11-19.png" alt="" v-if="picture19"></div>
                        <div class="picture"><span>20</span><img src="./assets/11-20.png" alt="" v-if="picture20"></div>

                        <div class="picture"><span>21</span><img src="./assets/11-21.png" alt="" v-if="picture21"></div>
                        <div class="picture"><span>22</span><img src="./assets/11-22.png" alt="" v-if="picture22"></div>
                        <div class="picture"><span>23</span><img src="./assets/11-23.png" alt="" v-if="picture23"></div>
                        <div class="picture"><span>24</span><img src="./assets/11-24.png" alt="" v-if="picture24"></div>
                        <div class="picture"><span>25</span><img src="./assets/11-25.png" alt="" v-if="picture25"></div>
                        <div class="picture"><span>26</span><img src="./assets/11-26.png" alt="" v-if="picture26"></div>
                        <div class="picture"><span>27</span><img src="./assets/11-27.png" alt="" v-if="picture27"></div>
                        <div class="picture"><span>28</span><img src="./assets/11-28.png" alt="" v-if="picture28"></div>
                        <div class="picture"><span>29</span><img src="./assets/11-29.png" alt="" v-if="picture29"></div>
                        <div class="picture"><span>30</span><img src="./assets/11-30.png" alt="" v-if="picture30"></div>

                        <div class="picture"><span>31</span><img src="./assets/11-31.png" alt="" v-if="picture31"></div>
                        <div class="picture"><span>32</span><img src="./assets/11-32.png" alt="" v-if="picture32"></div>
                        <div class="picture"><span>33</span><img src="./assets/11-33.png" alt="" v-if="picture33"></div>
                        <div class="picture"><span>34</span><img src="./assets/11-34.png" alt="" v-if="picture34"></div>
                        <div class="picture"><span>35</span><img src="./assets/11-35.png" alt="" v-if="picture35"></div>
                        <div class="picture"><span>36</span><img src="./assets/11-36.png" alt="" v-if="picture36"></div>
                        <div class="picture"><span>37</span><img src="./assets/11-37.png" alt="" v-if="picture37"></div>
                        <div class="picture"><span>38</span><img src="./assets/11-38.png" alt="" v-if="picture38"></div>
                        <div class="picture"><span>39</span><img src="./assets/11-39.png" alt="" v-if="picture39"></div>
                        <div class="picture"><span>40</span><img src="./assets/11-40.png" alt="" v-if="picture40"></div>

                        <div class="picture"><span>41</span><img src="./assets/11-41.png" alt="" v-if="picture41"></div>
                        <div class="picture"><span>42</span><img src="./assets/11-42.png" alt="" v-if="picture42"></div>
                        <div class="picture"><span>43</span><img src="./assets/11-43.png" alt="" v-if="picture43"></div>
                        <div class="picture"><span>44</span><img src="./assets/11-44.png" alt="" v-if="picture44"></div>
                        <div class="picture"><span>45</span><img src="./assets/11-45.png" alt="" v-if="picture45"></div>
                        <div class="picture"><span>46</span><img src="./assets/11-46.png" alt="" v-if="picture46"></div>
                        <div class="picture"><span>47</span><img src="./assets/11-47.png" alt="" v-if="picture47"></div>
                        <div class="picture"><span>48</span><img src="./assets/11-48.png" alt="" v-if="picture48"></div>
                        <div class="picture"><span>49</span><img src="./assets/11-49.png" alt="" v-if="picture49"></div>
                        <div class="picture"><span>50</span><img src="./assets/11-50.png" alt="" v-if="picture50"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import Vue from "vue";

export default {
    name: 'App',

    data() {
        return {
            currentDay: moment().format('D-M-Y'),
            foundResponse: null,
            foundDay1: true,
            foundDay2: false,
            response: null,
            days: {
                '25-10-2021': [{
                    enigma: 1,
                    date: '25-10-2021',
                    theme: 'Géographie',
                    description: 'Quel est le plus grand pays du monde ?',
                    response: 'Russie',
                    pictures: ['picture4'],
                    view: true,
                    found: true
                }, {
                    enigma: 2,
                    date: '25-10-2021',
                    theme: 'Géographie',
                    description: 'Quel est le plus long fleuve français ?',
                    response: 'La Loire',
                    pictures: ['picture19'],
                    view: true,
                    found: true
                }, {
                    enigma: 3,
                    date: '25-10-2021',
                    theme: 'Histoire',
                    description: 'Quelle est la date de l’armistice de la 1ère guerre mondiale ?',
                    response: '11/11/1918',
                    pictures: ['picture42'],
                    view: true,
                    found: true
                }, {
                    enigma: 4,
                    date: '25-10-2021',
                    theme: 'Histoire',
                    description: 'En quelle année a eu lieu la chute du mur de Berlin ?',
                    response: '1989',
                    pictures: ['picture12'],
                    view: true,
                    found: true
                }, {
                    enigma: 5,
                    date: '25-10-2021',
                    theme: 'Sport',
                    description: 'Quand ont eu lieu les derniers Jeux Olympiques à Paris ?',
                    response: '1924',
                    pictures: ['picture10'],
                    view: true,
                    found: null
                }, {
                    enigma: 6,
                    date: '25-10-2021',
                    theme: 'Sport',
                    description: 'Quand la France a-t-elle gagné sa 1ère coupe du monde de foot ?',
                    response: '12/07/1998',
                    pictures: ['picture1'],
                    view: true,
                    found: true
                }, {
                    enigma: 7,
                    date: '25-10-2021',
                    theme: 'Cinéma',
                    description: 'Quel film français comptabilise le plus grande nombre d’entrées au cinéma en France ?',
                    response: 'Bienvenue chez les ch\'tis',
                    pictures: ['picture7'],
                    view: true,
                    found: true
                }, {
                    enigma: 8,
                    date: '25-10-2021',
                    theme: 'Cinéma',
                    description: 'Quel film français des années 60 a longtemps détenu ce record auparavant (le plus grande nombre d’entrées au cinéma) ?',
                    response: 'La grande vadrouille',
                    pictures: ['picture49'],
                    view: true,
                    found: true
                }, {
                    enigma: 9,
                    date: '25-10-2021',
                    theme: 'Musique',
                    description: 'Quelle star mondiale de la musique nous a quitté le 25 juin 2009 ?',
                    response: 'Michael Jackson',
                    pictures: ['picture14'],
                    view: true,
                    found: true
                }, {
                    enigma: 10,
                    date: '25-10-2021',
                    theme: 'Musique',
                    description: 'De quelle ville les Beattles sont-ils originaires ?',
                    response: 'Liverpool',
                    pictures: ['picture31'],
                    view: true,
                    found: true
                }],
                '26-10-2021': [{
                    enigma: 1,
                    date: '26-10-2021',
                    theme: 'Géographie',
                    description: 'Quel est l’altitude du Mont Blanc ?',
                    response: '4807',
                    pictures: ['picture38'],
                    view: true,
                    found: null
                }, {
                    enigma: 2,
                    date: '26-10-2021',
                    theme: 'Géographie',
                    description: 'Quel est le plus grand océan du monde ?',
                    response: 'Pacifique',
                    pictures: ['picture45'],
                    view: true,
                    found: null
                }, {
                    enigma: 3,
                    date: '26-10-2021',
                    theme: 'Histoire',
                    description: 'Quand a été instauré la Ve République en France ?',
                    response: '1958',
                    pictures: ['picture3'],
                    view: true,
                    found: null
                }, {
                    enigma: 4,
                    date: '26-10-2021',
                    theme: 'Histoire',
                    description: 'En quelle année la base américaine de Pearl Harbor a-t-elle été attaquée ?',
                    response: '1941',
                    pictures: ['picture41'],
                    view: true,
                    found: null
                }, {
                    enigma: 5,
                    date: '26-10-2021',
                    theme: 'Sport',
                    description: 'Qui marque le dernier but en finale ?',
                    response: 'Emmanuel Petit',
                    pictures: ['picture47'],
                    view: true,
                    found: null
                }, {
                    enigma: 6,
                    date: '26-10-2021',
                    theme: 'Sport',
                    description: 'En athlétisme, qui détient le record du monde du 100m ?',
                    response: 'Usain Bolt',
                    pictures: ['picture2'],
                    view: true,
                    found: null
                }, {
                    enigma: 7,
                    date: '26-10-2021',
                    theme: 'Cinéma',
                    description: 'Quel réalisateur américain a réalisé notamment « Pulp fiction » ou « Inglourious Basterds »?',
                    response: 'Quentin Tarantino',
                    pictures: ['picture48'],
                    view: true,
                    found: null
                }, {
                    enigma: 8,
                    date: '26-10-2021',
                    theme: 'Cinéma',
                    description: 'Quel film culte avec Patrick Swayze et Jennifer Grey sort en 1987 ?',
                    response: 'Dirty Dancing',
                    pictures: ['picture6'],
                    view: true,
                    found: null
                }, {
                    enigma: 9,
                    date: '26-10-2021',
                    theme: 'Musique',
                    description: 'Quel groupe a sorti l’album « Ok computer » en 1997 ?',
                    response: 'Radiohead',
                    pictures: ['picture13'],
                    view: true,
                    found: null
                }, {
                    enigma: 10,
                    date: '26-10-2021',
                    theme: 'Musique',
                    description: 'Quel chanteur-guitariste d’un groupe de rock alternatif américain est décédé en 1994 à l’âge de 27ans ?',
                    response: 'Kurt Cobain',
                    pictures: ['picture50'],
                    view: true,
                    found: null
                }],
                '27-10-2021': [{
                    enigma: 1,
                    date: '27-10-2021',
                    theme: 'Géographie',
                    description: 'Quelle est la capitale de la Suisse ?',
                    response: 'Berne',
                    pictures: ['picture32'],
                    view: true,
                    found: null
                }, {
                    enigma: 2,
                    date: '27-10-2021',
                    theme: 'Géographie',
                    description: 'Quelle est la capitale de l’Australie ?',
                    response: 'Canberra',
                    pictures: ['picture15'],
                    view: true,
                    found: null
                }, {
                    enigma: 3,
                    date: '27-10-2021',
                    theme: 'Histoire',
                    description: 'Quel est le surnom du roi Louis XIV ?',
                    response: 'Le Roi Soleil',
                    pictures: ['picture8'],
                    view: true,
                    found: null
                }, {
                    enigma: 4,
                    date: '27-10-2021',
                    theme: 'Histoire',
                    description: 'Comment s’appelle la fameuse galerie de son château fétiche ?',
                    response: 'La galerie des Glaces',
                    pictures: ['picture5'],
                    view: true,
                    found: null
                }, {
                    enigma: 5,
                    date: '27-10-2021',
                    theme: 'Sport',
                    description: 'Quel est le temps de ce record du monde ?',
                    response: '9,58',
                    pictures: ['picture46'],
                    view: true,
                    found: null
                }, {
                    enigma: 6,
                    date: '27-10-2021',
                    theme: 'Sport',
                    description: 'En quelle année ont eu lieu les Jeux Olympiques d’hiver d’Albertville ?',
                    response: '1992',
                    pictures: ['picture40'],
                    view: true,
                    found: null
                }, {
                    enigma: 7,
                    date: '27-10-2021',
                    theme: 'Cinéma',
                    description: 'Quel est le 3ème film du triptyque Die Hard sorti en 1995 ?',
                    response: 'Une journee en enfer',
                    pictures: ['picture21'],
                    view: true,
                    found: null
                }, {
                    enigma: 8,
                    date: '27-10-2021',
                    theme: 'Cinéma',
                    description: 'En quelle année sort le 1er film Rocky ?',
                    response: '1976',
                    pictures: ['picture18'],
                    view: true,
                    found: null
                }, {
                    enigma: 9,
                    date: '27-10-2021',
                    theme: 'Musique',
                    description: 'Quel artiste français a fait toute sa carrière sur un titre mondialement connu depuis la fin des années 70 ?',
                    response: 'Patrick Hernandez',
                    pictures: ['picture29'],
                    view: true,
                    found: null
                }, {
                    enigma: 10,
                    date: '27-10-2021',
                    theme: 'Musique',
                    description: 'Quelle chanson Jean-Jacques Goldman interprète-t-il en duo avec Sirima en 1987?',
                    response: 'La bas',
                    pictures: ['picture11'],
                    view: true,
                    found: null
                }],
                '28-10-2021': [{
                    enigma: 1,
                    date: '28-10-2021',
                    theme: 'Géographie',
                    description: 'Quel est le nombre d’états aux États-Unis ?',
                    response: '50',
                    pictures: ['picture20'],
                    view: true,
                    found: null
                }, {
                    enigma: 2,
                    date: '28-10-2021',
                    theme: 'Géographie',
                    description: 'Quel est le nombre de départements français ?',
                    response: '101',
                    pictures: ['picture9'],
                    view: true,
                    found: null
                }, {
                    enigma: 3,
                    date: '28-10-2021',
                    theme: 'Histoire',
                    description: 'En quelle année Christophe Colomb a-t-il découvert l’Amérique ?',
                    response: '1492',
                    pictures: ['picture43'],
                    view: true,
                    found: null
                }, {
                    enigma: 4,
                    date: '28-10-2021',
                    theme: 'Histoire',
                    description: 'En quelle année a eu lieu le 1er choc pétrolier ?',
                    response: '1973',
                    pictures: ['picture22'],
                    view: true,
                    found: null
                }, {
                    enigma: 5,
                    date: '28-10-2021',
                    theme: 'Sport',
                    description: 'Combien Bernard Hinault a-t-il gagné de Tour de France ?',
                    response: '5',
                    pictures: ['picture28'],
                    view: true,
                    found: null
                }, {
                    enigma: 6,
                    date: '28-10-2021',
                    theme: 'Sport',
                    description: 'Après son nouveau sacre au JO de Tokyo cette année, combien de titres Olympiques l’équipe de France masculine de Handball a-t-elle remportée ?',
                    response: '3',
                    pictures: ['picture39'],
                    view: true,
                    found: null
                }, {
                    enigma: 7,
                    date: '28-10-2021',
                    theme: 'Cinéma',
                    description: 'Quel est le réalisateur du film « Amélie Poulain »?',
                    response: 'Jean Pierre Jeunet',
                    pictures: ['picture44'],
                    view: true,
                    found: null
                }, {
                    enigma: 8,
                    date: '28-10-2021',
                    theme: 'Cinéma',
                    description: 'Quel film sorti en 1994 révèle la jeune actrice Nathalie Portman ?',
                    response: 'Leon',
                    pictures: ['picture30'],
                    view: true,
                    found: null
                }, {
                    enigma: 9,
                    date: '28-10-2021',
                    theme: 'Musique',
                    description: 'Comment appelle-t-on le style musical provenant de Corée du Sud ?',
                    response: 'K pop',
                    pictures: ['picture16'],
                    view: true,
                    found: null
                }, {
                    enigma: 10,
                    date: '28-10-2021',
                    theme: 'Musique',
                    description: 'De quel groupe Brian Johnson et Angus Young sont-ils issus ?',
                    response: 'ACDC',
                    pictures: ['picture34'],
                    view: true,
                    found: null
                }],
                '29-10-2021': [{
                    enigma: 1,
                    date: '29-10-2021',
                    theme: 'Géographie',
                    description: 'Quel est le pays le plus peuplé au monde ?',
                    response: 'Chine',
                    pictures: ['picture17'],
                    view: true,
                    found: null
                }, {
                    enigma: 2,
                    date: '29-10-2021',
                    theme: 'Géographie',
                    description: 'Quel est le plus petit département Français ?',
                    response: 'Paris',
                    pictures: ['picture33'],
                    view: true,
                    found: null
                }, {
                    enigma: 3,
                    date: '29-10-2021',
                    theme: 'Histoire',
                    description: 'En quelle année fut assassiné John Fitzgerald Kennedy ?',
                    response: '1963',
                    pictures: ['picture37'],
                    view: true,
                    found: null
                }, {
                    enigma: 4,
                    date: '29-10-2021',
                    theme: 'Histoire',
                    description: 'A quel siècle la construction de la cathédrale Notre Dame a-t-elle commencé ?',
                    response: '12',
                    pictures: ['picture23'],
                    view: true,
                    found: null
                }, {
                    enigma: 5,
                    date: '29-10-2021',
                    theme: 'Sport',
                    description: 'Quelle légende du foot mondial nous a quitté en 2020 ?',
                    response: 'Maradona',
                    pictures: ['picture36'],
                    view: true,
                    found: null
                }, {
                    enigma: 6,
                    date: '29-10-2021',
                    theme: 'Sport',
                    description: 'Comment s’appelle la plus grande course de vélo de la Mayenne ?',
                    response: 'Les boucles de la Mayenne',
                    pictures: ['picture27'],
                    view: true,
                    found: null
                }, {
                    enigma: 7,
                    date: '29-10-2021',
                    theme: 'Cinéma',
                    description: 'Dans quel film culte français danse-t-on la carioca ?',
                    response: 'La cite de la peur',
                    pictures: ['picture35'],
                    view: true,
                    found: null
                }, {
                    enigma: 8,
                    date: '29-10-2021',
                    theme: 'Cinéma',
                    description: 'Quel film de Christopher Nolan avec notamment Matthew McConaughey joue avec l’espace-temps ?',
                    response: 'Interstellar',
                    pictures: ['picture26'],
                    view: true,
                    found: null
                }, {
                    enigma: 9,
                    date: '29-10-2021',
                    theme: 'Musique',
                    description: 'Quel groupe est composé notamment des frères Gallagher ?',
                    response: 'Oasis',
                    pictures: ['picture24'],
                    view: true,
                    found: null
                }, {
                    enigma: 10,
                    date: '29-10-2021',
                    theme: 'Musique',
                    description: 'De quel groupe faisait parti auparavant Gaëtan Roussel ?',
                    response: 'Louise Attaque',
                    pictures: ['picture25'],
                    view: true,
                    found: null
                }]
            },
            picture1: true,
            picture2: true,
            picture3: true,
            picture4: true,
            picture5: false,
            picture6: true,
            picture7: true,
            picture8: false,
            picture9: false,
            picture10: false,
            picture11: false,
            picture12: true,
            picture13: false,
            picture14: true,
            picture15: false,
            picture16: false,
            picture17: false,
            picture18: false,
            picture19: true,
            picture20: false,
            picture21: false,
            picture22: false,
            picture23: false,
            picture24: false,
            picture25: false,
            picture26: false,
            picture27: false,
            picture28: false,
            picture29: false,
            picture30: false,
            picture31: true,
            picture32: false,
            picture33: false,
            picture34: false,
            picture35: false,
            picture36: false,
            picture37: false,
            picture38: true,
            picture39: false,
            picture40: false,
            picture41: true,
            picture42: true,
            picture43: false,
            picture44: false,
            picture45: true,
            picture46: false,
            picture47: true,
            picture48: true,
            picture49: true,
            picture50: true,
        }
    },

    mounted() {

        Vue.$cookies.config('30d');
        
        if (this.$cookies.isKey('pictures')) {
            let pictures = JSON.parse(this.$cookies.get('pictures'));

            pictures.forEach(picture => {
                this[picture] = true;
            });

            for (let day in this.days) {
                for (let enigma in this.days[day]) {
                    this.days[day][enigma].pictures.forEach(picture => {
                        if (pictures.includes(picture)) {
                            this.days[day][enigma].view = false;
                        }
                    })
                }
            }
        }
    },

    methods: {
        submit(day, reference) {
            let response = this.$refs[reference] && this.$refs[reference][0] && this.$refs[reference][0].value ? this.$refs[reference][0].value.toLowerCase().trim() : null;

            if (response) {
                let responsesFound = [];

                if (this.$cookies.isKey('responses')) {
                    JSON.parse(this.$cookies.get('responses')).forEach(response => {
                        responsesFound.push(response);
                    });
                }

                responsesFound.push(day.date + ' (' + day.enigma + ') : ' + response);

                this.$cookies.set('responses', JSON.stringify(responsesFound));
            }

            if (response && response === day.response.toLowerCase().trim()) {
                let picturesFound = [];

                if (this.$cookies.isKey('pictures')) {
                    JSON.parse(this.$cookies.get('pictures')).forEach(picture => {
                        picturesFound.push(picture);
                    });
                }

                this.foundResponse = true;

                day.pictures.forEach((picture) => {
                    this[picture] = true;

                    picturesFound.push(picture);

                    day.view = false;
                });

                this.$cookies.set('pictures', JSON.stringify(picturesFound));
            } else {
                this.foundResponse = false;

                day.found = false;
            }
        },
    }
}
</script>
<!-- https://www.deleze.name/marcel/photo/subimage/DecoupeImage.html -->
<style>
body {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    background-image: url('./assets/5502768.jpg');
    background-size: 100%;
    background-repeat: no-repeat;
    margin: 15px;
    background-color: #EFAC98;
}

.pictures {
    width: 100%;
}

table, tr, td {
    border: none;
}

.picture {
    width: 10%;
    display: inline-block;
}

.picture span {
    position: absolute;
    font-size: 20px;
    display: none;
}

.picture img {
    width: 100%;
}
</style>
