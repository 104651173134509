import Vue from 'vue'
import App from './App.vue'
import moment from 'moment'
import VueCookies from 'vue-cookies'

Vue.config.productionTip = false
Vue.use(VueCookies);

new Vue({
  render: h => h(App, VueCookies, moment),
}).$mount('#app')
